<template>
  <b-modal :id="id" ref="virtualTourModalRef" size="lg" hide-header hide-footer modal-class="virtual-tour-dialog" content-class="virtual-tour-content">
    <button type="button" class="close margin-fix" data-dismiss="modal" aria-label="Close" @click="$refs['virtualTourModalRef'].hide()">
    <img src="../assets/images/close-icon-modal.svg" width="30" height="30" class="img-fluid close-icon">
  </button>
    <iframe class="full-screen-video" :src="url" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </b-modal>
</template>
<script>
  export default {
    props:{
      url:{
        type:String,
        default:'https://player.vimeo.com/video/998056054?h=3d2b4d6faf'
      },
      id:{
        type:String,
        default:'virtualTour'
      }
    }
  }
</script>
<style scoped>
	.full-screen-video{
		height: 100%;
		width: 100%;
    min-height:300px;
	}

  @media (min-width:992px){
    .full-screen-video{
      min-height:500px;
    }
  }
</style>